// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tots-js": () => import("./../../../src/pages/tots.js" /* webpackChunkName: "component---src-pages-tots-js" */),
  "component---src-templates-catsmap-js": () => import("./../../../src/templates/catsmap.js" /* webpackChunkName: "component---src-templates-catsmap-js" */),
  "component---src-templates-singlemap-js": () => import("./../../../src/templates/singlemap.js" /* webpackChunkName: "component---src-templates-singlemap-js" */),
  "component---src-templates-tagsmap-js": () => import("./../../../src/templates/tagsmap.js" /* webpackChunkName: "component---src-templates-tagsmap-js" */)
}

